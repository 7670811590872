import React from 'react'
import { graphql } from 'gatsby'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import Layout from "../components/layout"
import ContactForm from '../components/contactForm';
import ContactInfo from '../components/contactInfo';

const Contact = ({ data: { contact } }) => (
  <Layout>
    <article className="sheet">
      <HelmetDatoCms seo={contact.seoMetaTags} />
      <div className="sheet__inner">
        <h1 className="sheet__title">{contact.title}</h1>
        <p className="sheet__lead">{contact.subtitle}</p>
        <div className="sheet__body">
          <ContactForm />
        </div>
        <div className="sheet__body">
          <ContactInfo />
        </div>
      </div>
    </article>
  </Layout>
)

export default Contact

export const query = graphql`
  query ContactFormQuery {
    contact: datoCmsContactForm {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      subtitle
    }
  }
`
