// Customize this 'myform.js' script and add it to your JS bundle.
// Then import it with 'import MyForm from "./myform.js"'.
// Finally, add a <MyForm/> element whereever you wish to display the form.

import React, { useState, useRef } from "react";
import ReCAPTCHA from 'react-google-recaptcha';

const ContactForm = () => {
  const CONTACT_FORM_ACTION = "https://formspree.io/mbjzwype";
  const RECAPTCHA_KEY = "6Lf2IrsZAAAAABsgKoLRa8uaO8A3ZzKkTRVDX6DS";

  const recaptchaRef = useRef();

  const [submitStatus, setSubmitStatus] = useState('PENDING');
  const [email, setEmail] = useState();
  const [message, setMessage] = useState();
  const [recaptchaResponse, setRecaptchaResponse] = useState();

  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const onMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const submitForm = (e) => {
    e.preventDefault();

    if (email && message && recaptchaResponse) {
      fetch(CONTACT_FORM_ACTION,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          method: 'POST',
          body: JSON.stringify({ email: email, message: message, 'g-recaptcha-response': recaptchaResponse })
        }
        )
        .then(response => {
          if (response.ok) { return response.json(); }

          throw new Error(response.error);
        })
        .then(data => {
          setSubmitStatus("SUCCESS");
        })
        .catch(error => {
          setSubmitStatus("ERROR");
        });
    }
  };

  return (
    <form onSubmit={submitForm}>

      <div>
        <label class="mdc-text-field mdc-text-field--filled mdc-text-field--no-label">
          <span class="mdc-text-field__ripple"></span>
          <input class="mdc-text-field__input email" type="text" placeholder="Email" aria-label="Label" value={email} onChange={onEmailChange} />
          <span class="mdc-line-ripple"></span>
        </label>
      </div>

      <div>
        <label class="mdc-text-field mdc-text-field--filled mdc-text-field--textarea mdc-text-field--no-label">
          <span class="mdc-text-field__ripple"></span>
          <span class="mdc-text-field__resizer">
            <textarea class="mdc-text-field__input message" rows="5" aria-label="Message" value={message} onChange={onMessageChange}></textarea>
          </span>
          <span class="mdc-line-ripple"></span>
        </label>
      </div>

      <div>
        <ReCAPTCHA ref={recaptchaRef} sitekey={RECAPTCHA_KEY} onChange={setRecaptchaResponse}/>
      </div>

      <input type="hidden" name="_subject" value="RMC Inquiry" />

      {
        submitStatus === "SUCCESS" ?
          <p className="success">Thanks for your inquiry, we will get back to you as soon as possible!</p> :
          <div className="mdc-touch-target-wrapper">
            <button className="mdc-button mdc-button--raised">
              <div className="mdc-button__ripple"></div>
              <span className="mdc-button__label">Submit</span>
              <div className="mdc-button__touch"></div>
            </button>
          </div>      
      }
      
      {submitStatus === "ERROR" && <p className="error">Failed to submit your inquiry, please try again later.</p>}
    </form>
  )
}

export default ContactForm;
