import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

const ContactInfo = ({ showEmailLink = false }) => (
    <StaticQuery
      query={graphql`
        query {
          allDatoCmsContact(sort: { fields: [position], order: ASC }) {
            edges {
              node {
                contactType
                url
                displayTextNode {
                  childMarkdownRemark {
                    html
                  }
                }
              }
            }
          }
        }
      `}
      render={data => (
        <div className="sheet__body">
          {data.allDatoCmsContact.edges.map(({ node: contact }) => {
            if (!showEmailLink && contact.contactType === "Email") {
              return null;
            }

            return (
              <div>
                <h3>{contact.contactType}</h3>
                <p>
                  <a
                    key={contact.contactType}
                    href={contact.url}
                    dangerouslySetInnerHTML={{
                        __html: contact.displayTextNode.childMarkdownRemark.html,
                    }}>
                  </a>
                </p>
              </div>
            );
          })}
        </div>
      )}
  />
)

export default ContactInfo
